import React from 'react';
import { AccountData } from '../types';
import { Box, Typography } from '@mui/material';

interface BlurOverlayProps {
    accountData: AccountData | null;
    requiredTier: number;
}

const BlurOverlay: React.FC<BlurOverlayProps> = ({ accountData, requiredTier }) => {
    const shouldBlur = accountData ? accountData.sub_tier < requiredTier : false;

    return shouldBlur ? ( // Conditionally render the overlay
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backdropFilter: 'blur(5px)',
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 10,
                pointerEvents: 'auto',
                paddingLeft: "16rem",
            }}
        >
            <Box sx={{ pointerEvents: 'auto' }}>
                <Typography variant="h4" sx={{ color: 'grey', fontWeight: 'bold', textAlign: "center" }}>Upgrade to Pro to unlock this feature</Typography>
            </Box>
        </Box>
    ) : null; // Return null if no blur is needed
};

export default BlurOverlay;
