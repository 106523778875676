import { useEffect, useRef, useState, useContext } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";

import SendIcon from "@mui/icons-material/Send";

// Type imports
import { Message } from "../types";
import { AuthContext } from "../auth";


/* Messagebox component */
function MessageBox({ contactId, messageHistory, tempUpdateMessages }: 
    { contactId: string, messageHistory: Array<Message>, tempUpdateMessages: (m: Message) => void }) {

	const [messageContent, setMessageContent] = useState("");
    const messagesEndRef = useRef<HTMLDivElement>(null); // Create a ref for scrolling


	const authInfo = useContext(AuthContext);
	if(!authInfo) {
		throw new Error("Auth context error!");
	};

    // Function to scroll to the bottom of the message box
    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    // Scroll to bottom on initial render and when messages change
    useEffect(() => {
      scrollToBottom();
    }, [messageHistory]);


    const sendMessage = async () => {
        if (messageContent.trim() !== "") { // Prevent sending empty messages

            // Display message immediately
            tempUpdateMessages({
                outgoing: true, 
                content: messageContent, 
                date: new Date().getTime()}
            );


            const res = await fetch("/send-pm", {
              method: "post",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                target_id: contactId,
                content: messageContent,
              }),
            });
            if(res.ok){
              setMessageContent("");
              authInfo.fetchUserData();
            } else {
                // Alert user message failed
            }
        }
    };

    return (
        <div className="flex flex-col w-full lg:w-1/2 bg-gray-200 p-4 h-full" style={{ height: "calc(100vh - 200px)" }}> {/* Outer container - wider on larger screens */}
          <div className="flex-grow overflow-y-auto pb-4" style={{ maxHeight: "calc(100vh - 280px)" }}>
            {messageHistory.map((message: Message, idx: number) => (
              <div
                key={idx}
                className={`my-2 clear-both w-fit max-w-[80%] ${message.outgoing ? "float-right" : "self-start"}`}
              >
                <div
                  className={`p-3 rounded-lg clear-both ${message.outgoing ? "bg-blue-500 text-white rounded-br-none float-right" : "bg-white rounded-bl-none float-left"}`}
                >
                  <p className="m-0 break-words">{message.content}</p>
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>

          {/* Message input box */}
          <div className="bg-white p-2 border-t border-gray-300">
            <div className="flex flex-row items-center justify-center">
              <TextField
                placeholder="Type your message"
                fullWidth
                value={messageContent}
                onChange={(event) => setMessageContent(event.target.value)}
                onKeyDown={async (event) => {
                  if (event.key === "Enter") {
                    await sendMessage();
                  }
                }}
              />
              <div className="w-2"></div>
              <IconButton color="primary" onClick={async () => await sendMessage()}>
                <SendIcon />
              </IconButton>
            </div>
          </div>
        </div>
    );
}

export default MessageBox;
