import React, { useState, useEffect } from 'react';
import { 
  Tab, 
  Tabs, 
  TextField, 
  Button, 
  Container, 
  Grid, 
  Typography, 
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import { loadStripe } from '@stripe/stripe-js';
import { 
  CardElement, 
  Elements, 
  useStripe, 
  useElements 
} from '@stripe/react-stripe-js';
import { FullLogo } from "../ui/logo";



// Stripe configuration (replace with your actual publishable key)
const stripePromise = loadStripe('pk_test_YOUR_STRIPE_PUBLISHABLE_KEY');



// Dark theme configuration (same as previous artifact)
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#0F172A',
      paper: '#1E293B',
    },
    primary: {
      main: '#3B82F6',
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#475569',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3B82F6',
          },
        },
      },
    },
  },
});


interface SignupFormData {
  email: string;
  phoneNumber: string;
  fullName: string;
  businessName: string;
  country: string;
  city: string;
  password: string;
  confirmPassword: string;
  timezone: number;
}

interface TierInformation {
    name: string,
    monthlyPrice: number,
    features: Array<string>,  // Features it provides
    missing: Array<string>,   // Features it doesn't provide
    info: string
}



// List of countries (expanded from previous version)
const COUNTRIES = [
  'Australia', 'New Zealand', 'United States', 'Canada', 'United Kingdom', 
  'Germany', 'France', 'Japan', 'Singapore', 'India'
  // Add more countries as needed
];

const TIER_INFORMATION: Array<TierInformation> = [
    {
        name: "Basic", 
        monthlyPrice: 230, 
        features: ["Booking Log", "Automated Booking Reminders", "Automatic Review Reminders", "Reporting Dashboard"], 
        missing: ["AI-powered ad campaign analysis", "Marketing message portal"], 
        info: ""
    },
    {
        name: "Pro", 
        monthlyPrice: 490, 
        features: ["Booking Log", "Automated Booking Reminders", "Automatic Review Reminders", "Reporting Dashboard", "AI-powered ad campaign analysis", "Marketing message portal"], 
        missing: [], 
        info: ""
    },
];

function formatFeatures(tier: TierInformation): React.ReactElement {
    return (
        <div className="ml-4 text-[#E2E8F0]">
            <ul className="list-['✓'] mt-4 text-lg">
                {tier.features.map(
                    (f: string, i: number) => <li key={i} className="pl-4">{f}</li>
                )}
            </ul>

            <ul className="list-['×'] text-lg text-gray-500">
                {tier.missing.map(
                    (m: string, i: number) => <li key={i} className="pl-4">{m}</li>
                )}
            </ul>
        </div>
    );
}


function SignupForm() {


  const urlParams = new URLSearchParams(String(window.location.search));
  const [tierValue, setTierValue] = useState(Number.parseInt(urlParams.get("init-tier") || "0"));

  const [formData, setFormData] = useState<SignupFormData>({
    email: '',
    phoneNumber: '',
    fullName: '',
    businessName: '',
    country: '',
    city: '',
    password: '',
    confirmPassword: '',
    timezone: new Date().getTimezoneOffset() 
  });

  const [passwordError, setPasswordError] = useState('');

  const stripe = useStripe();
  const elements = useElements();

  const handleTierChange = (event: React.SyntheticEvent, newValue: number) => {
    setTierValue(newValue);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    
    // Special handling for password confirmation
    if (name === 'confirmPassword') {
      if (formData.password !== value) {
        setPasswordError('Passwords do not match');
      } else {
        setPasswordError('');
      }
    }

    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Additional validation
    if (formData.password !== formData.confirmPassword) {
      alert('Passwords do not match');
      return;
    }

    if (!stripe || !elements) {
      return;
    }

    // Validate form data
    const isFormValid = Object.entries(formData)
      .filter(([key]) => key !== 'timezone') // Exclude timezone from validation
      .every(([key, value]) => value.trim() !== '');

    if (!isFormValid) {
      alert('Please fill out all fields');
      return;
    }

    try {
      // Create payment method with Stripe
      const cardElement = elements.getElement(CardElement);
      if (!cardElement) return;

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
            

      if (error) {
        console.error('[payment method creation error]', error);
        alert('Payment setup failed. Please check your card details.');
        return;
      }

      // Prepare submission data (excluding confirmPassword)
      const { confirmPassword, ...submissionData } = formData;
      const finalSubmissionData = {
        ...submissionData,
        tier: TIER_INFORMATION[tierValue].name,
        paymentMethodId: paymentMethod?.id,
        price: TIER_INFORMATION[tierValue].monthlyPrice
      };

      // TODO: Implement backend API call to create user and process payment
      console.log('Submission Data:', finalSubmissionData);
      
      alert('Sign up successful!');
    } catch (error) {
      console.error('Signup error:', error);
      alert('Signup failed. Please try again.');
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Container 
        maxWidth={false}
        className="min-h-screen flex flex-col items-center justify-center p-4"
        style={{ backgroundColor: '#0F172A' }}
      >
        <Box className="w-full max-w-4xl" sx={{"margin": "auto"}}>
          <FullLogo className="mb-6 mx-auto" />
          
          <Tabs 
            value={tierValue} 
            onChange={handleTierChange} 
            centered 
            className="mb-6"
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab label="Basic" />
            <Tab label="Pro" />
          </Tabs>

          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* Account Information Box */}
              <Grid item xs={12} md={6}>
                <Box 
                  className="h-full p-4 rounded-lg" 
                  sx={{ 
                    bgcolor: 'background.paper', 
                    border: '1px solid',
                    borderColor: 'divider'
                  }}
                >
                  <Typography variant="h6" gutterBottom color="primary">
                    Account Information
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Full Name"
                        name="fullName"
                        value={formData.fullName}
                        onChange={(x: any) => handleInputChange(x)}
                        margin="normal"
                        required
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Business Name"
                        name="businessName"
                        value={formData.businessName}
                        onChange={(x: any) => handleInputChange(x)}
                        margin="normal"
                        required
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Email"
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={(x: any) => handleInputChange(x)}
                        margin="normal"
                        required
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Phone Number"
                        type="tel"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={(x: any) => handleInputChange(x)}
                        margin="normal"
                        required
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth margin="normal" required variant="outlined">
                        <InputLabel>Country</InputLabel>
                        <Select
                          name="country"
                          value={formData.country}
                          label="Country"
                          onChange={(x: any) => handleInputChange(x)}
                        >
                          {COUNTRIES.map((country) => (
                            <MenuItem key={country} value={country}>
                              {country}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="City"
                        name="city"
                        value={formData.city}
                        onChange={(x: any) => handleInputChange(x)}
                        margin="normal"
                        required
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Timezone"
                        name="timezone"
                        value={Intl.DateTimeFormat().resolvedOptions().timeZone}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                        margin="normal"
                        helperText="Automatically detected from your system"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Password"
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={(x: any) => handleInputChange(x)}
                        margin="normal"
                        required
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Confirm Password"
                        type="password"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={(x: any) => handleInputChange(x)}
                        margin="normal"
                        required
                        variant="outlined"
                        error={!!passwordError}
                        helperText={passwordError}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              {/* Payment Information Box */}
              <Grid item xs={12} md={6}>
                <Box 
                  className="h-full p-4 rounded-lg" 
                  sx={{ 
                    bgcolor: 'background.paper', 
                    border: '1px solid',
                    borderColor: 'divider'
                  }}
                >
                  <Typography variant="h6" gutterBottom color="primary">
                    Payment Information
                  </Typography>
                  <Box className="border rounded p-3 mb-3" sx={{ borderColor: 'divider' }}>
                    <CardElement 
                      options={{
                        style: {
                          base: {
                            fontSize: '16px',
                            color: '#E2E8F0',
                            '::placeholder': {
                              color: '#64748B',
                            },
                          },
                          invalid: {
                            color: '#F87171',
                          },
                        },
                      }}
                    />
                  </Box>
                  <div>
                    <Typography variant="h4" color="textSecondary" className="mt-2">
                        ${TIER_INFORMATION[tierValue].monthlyPrice}/month
                    </Typography>
                    <Typography variant="body2" color="textSecondary" className="mt-2">
                        {TIER_INFORMATION[tierValue].name} tier
                    </Typography>
                    {formatFeatures(TIER_INFORMATION[tierValue])}
                  </div>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Button 
                  type="submit" 
                  variant="contained" 
                  color="primary" 
                  fullWidth 
                  size="large"
                  className="mt-4"
                >
                  Create Account
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

// Wrap the SignupForm with Stripe Elements
export default function Signup() {
  return (
    <Elements stripe={stripePromise}>
      <SignupForm />
    </Elements>
  );
}
