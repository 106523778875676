

// MongoDB Compatability Types
export type MDate = {
    $date: {
        $numberLong: string
    }
}

export type ObjectId = {
    $oid: string
}

// Creates a Date object from and MDate
export function parseMDate(d: MDate): Date {
    const timestamp = Number(d.$date.$numberLong);
    return new Date(timestamp); 
}

// Creates an MDate from a Date object
export function newMDate(d: Date): MDate {
  const unixTimestamp = Math.floor(d.getTime() / 1000);
  return {
    $date: {
      $numberLong: String(unixTimestamp)
    }
  };
}

export function addSecondsToDate(d: Date, s: number): Date {
    const ms = s * 1000;

    return new Date(d.getTime() + ms);

}


export function formatDateTime(date: Date): string {
  const year = date.getFullYear();
  const month = date.toLocaleString('default', { month: 'long' });
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${day} ${month} ${year}, ${hours}:${minutes}`;
}


export type AuthContextType = {
	authed: boolean, // This is account auth!
	fetchingAuth: boolean,
	login: () => void,
	logout: () => void,
	googleAuthed: boolean,
	authGoogle: () => void,
	unauthGoogle: () => void,
	userData: UserData | null,
	fetchUserData: () => Promise<void>,
	forceUpdateCampaignCache: () => Promise<void>
};

export type CampaignOverview = {
	status: string,
	name: string,
	id: string,
	startDate: string,
	endDate: string,
	amountMicros: string
};

export type AdMetrics = {
	clicks: string,
	conversionsValue: number,
	conversions: number,
	costMicros: string,
	impressions: string
};

export type Campaign = {
	campaign: CampaignOverview,
	metrics: AdMetrics
};

export type ReviewStats = {
	new: {avg: number, total: number},
	old: {date_checked: MDate, total: number, avg: number}
};

export type CampaignApiCache = {
	ads: Array<Campaign>,
	reviews: ReviewStats,
	ai: string,
	currency: string
};

export type AiSupplement = {
	summary: string,
	demographics: string,
	ad_goals: string
};

export type AccountData = {
	_id: ObjectId,
	name: string,
	email: string,
	location: string,
	sub_tier: number,
	time_zone_offset: number,
	appointment_message: string,
	review_message: string,
	contact_name: string,
	phone: string,
	prev_reviews: any, //TODO I THINK I WANT TO JUST REMOVE THIS
	country: string,
	is_restaurant: boolean,
	currency: string,
	ai_supplement?: AiSupplement,
	customer_id: string,
	manager_id?: string, // Optional
	api_cache: CampaignApiCache, 
};



export type Booking = {
	_id: ObjectId,
    business: string,
	first_name: string,
	last_name: string,
	phone: string,
	email?: string,
	appt_date?: MDate, // i think?
	general_info?: string,
	appt_reminder_sent: boolean,
	review_reminder_sent: boolean,
	contactable: boolean,
	message_history?: Array<Message>
};

export type ScheduledMessage = {
	_id: string,
	company: string, // email
	datetime: MDate,
	message: string,
	repeat: any //TODO
};

export type RequestedBooking = {
	business: string,
	first_name: string,
	last_name: string,
	phone: string,
	email: string,
	appt_date: MDate,
	general_info?: string,
	appt_reminder_sent: boolean,
	review_reminder_sent: boolean
};

export interface Message {
	outgoing: boolean, // True => sent by us, False => received
	content: string,
    date: number
};

export type UserData = {
	account_data: AccountData,
	booking_list: Array<Booking>,
	scheduled_messages: Array<ScheduledMessage>,
	google_authed: boolean, // THIS IS GOOGLE AUTH!!!!!
	pending_bookings: Array<RequestedBooking>,
};

export enum BusinessType {
	GROUP_BOOKING,
	NON_APPOINTMENT,
	ONE_ON_ONE
}

