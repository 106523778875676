import { Alert, Button } from '@mui/material';
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "../auth";
import { FullLogo } from '../ui/logo';

const logo = require('../images/white-logo.png');


export default function Login() {

  const authInfo = useContext(AuthContext);

  if (!authInfo) throw new Error("Invalid auth context.");


  let navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null); // State for error message

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Get token from server
    const response = await fetch('/login',
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({'email': email, 'password': password})
      }
    );

    const data = await response.json();

    // If a token was returned, log user in
    if (data.status === 'success' && authInfo) {
      console.log("Successfully logged in!");
      authInfo.login();
      authInfo.fetchUserData();
      navigate("/overview");

    } else if (data.status !== "success") {
      setError(data.status || "Login failed. Please check your credentials."); // Set error message
    }
  }

  // Redirect on page load if token is valid
  useEffect(() => {
    fetch('/validate-token')
      .then(response => {
        if (response.ok && !response.url.includes("localhost")) {
          console.log(response);
          navigate("/overview");
        } else {
          // handle error or redirect to login page if token is invalid
        }
      })
      .catch(error => console.error('Error validating token:', error));
  }, [authInfo.fetchingAuth]);

  return (
    <div className="w-screen h-screen bg-midnight">


      <form onSubmit={handleLogin} className="flex flex-col w-full md:w-1/2 lg:w-1/3 m-auto absolute md:left-1/4 lg:left-1/3 p-8 top-[20%]">

        {/*<img src={logo} alt="Startbase Management Logo" className="w-full m-auto rounded-lg mb-6"/>*/}
        <FullLogo className="m-auto mb-6" />
        {/* <h1 className='text-5xl text-center font-semibold text-accent mb-2'>Startbase Management</h1> */}
        <h2 className='text-center text-white text-lg'>Elevate your business with streamlined automation.</h2>

        <h1 className='text-center text-3xl font-bold text-white mb-8 mt-10'>Sign in</h1>

        {error && (  // Conditionally render the error box
          <Alert severity="error" sx={{marginBottom: "2rem"}}>{error}</Alert>
        )}

        <input placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} name='email' className="text-black border-black  p-1 rounded-sm mb-3"></input>

        <input placeholder='Password' type='password' value={password} onChange={(e) => setPassword(e.target.value)} name='password' className="text-black border-black p-1 rounded-sm mb-6"></input>

        <Button type='submit' variant='contained'>Sign In</Button>

        <h3 className='text-center mt-4 text-md text-white'>Don't have an account? <a className='font-semibold hover:underline text-cyan-500' href="/signup">Sign up</a></h3>

      </form>
    </div>
  );
}
