import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { AuthContext } from "../auth";
import { Booking, Message } from "../types";

// MUI Imports
import Button from "@mui/material/Button";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


// Custom component imports
import MessageBox from "./messages";
import ContactInformation from "./contact-info";

//const fallbackMessages: Array<Message> = 
//	[
//		{outgoing: true, content: "Test outgoing!"},
//		{outgoing: false, content: "Response!"},
//		{outgoing: true, content: "Chained message 1"},
//		{outgoing: true, content: "Chained message 2"},
//		{outgoing: true, content: "Test outgoing!"},
//		{outgoing: true, content: "Test outgoing!"},
//		{outgoing: false, content: "Response!"},
//		{outgoing: true, content: "Chained message 1"},
//		{outgoing: true, content: "Chained message 2"},
//		{outgoing: false, content: "Response!"},
//		{outgoing: true, content: "Chained message 1"},
//		{outgoing: true, content: "Chained message 2"},
//		{outgoing: true, content: "Test outgoing!"},
//		{outgoing: false, content: "Response!"},
//		{outgoing: true, content: "Chained message 1"},
//		{outgoing: true, content: "Chained message 2"},
//		{outgoing: true, content: "Test outgoing!"},
//		{outgoing: true, content: "Test outgoing!"},
//		{outgoing: false, content: "Response!"},
//		{outgoing: true, content: "Chained message 1"},
//		{outgoing: true, content: "Chained message 2"},
//		{outgoing: false, content: "Response!"},
//		{outgoing: true, content: "Chained message 1"},
//		{outgoing: true, content: "Chained message 2"},
//		{outgoing: true, content: "Test outgoing!"},
//		{outgoing: false, content: "Response!"},
//		{outgoing: true, content: "Chained message 1"},
//		{outgoing: true, content: "Chained message 2"},
//		{outgoing: true, content: "Test outgoing!"},
//		{outgoing: true, content: "Test outgoing!"},
//		{outgoing: false, content: "Response!"},
//		{outgoing: true, content: "Chained message 1"},
//		{outgoing: true, content: "Chained message 2"},
//		{outgoing: false, content: "Response!"},
//		{outgoing: true, content: "Chained message 1"},
//		{outgoing: true, content: "Chained message 2"},
//	];

/**
	Displays all information about a business's contact
*/
function ContactProfile() {

	const authInfo = useContext(AuthContext);

	const [searchParams, setSearchParams] = useSearchParams();
	const [contactInfo, setContactInfo] = useState<Booking | null>(null);
	const navigate = useNavigate();

	if(!authInfo) throw new Error("Invalid auth context.");


	// Get contact ID (for DB reference)
	const id: string | null = searchParams.get("id");
	if(id === null) {
		// Redirect back to booking overview
		navigate("/bookings");
	}



	// Request contact data from server on page loadS
	async function getContactInfo() {
		const res = await fetch(`/get-contact-info?id=${id}`);
		if(res.status !== 200) {
			// Error fetching data, display to user
			return;
		}

		const data = await res.json();

		setContactInfo(data);
	}

    function tempUpdateMessageHistory(m: Message): void {
        let x = {...contactInfo};
        x.message_history?.push(m);

        setContactInfo(x as Booking);
    }


	useEffect(() => {
		// Ensure user is authenticated
		if(!authInfo.authed && !authInfo.fetchingAuth) {
			window.location.href = "/login";
		}
		//setContactInfo(
		//{
		//	_id: {$oid: "abcd"},
        //    business: "Test Company",
		//	first_name: "Example",
		//	last_name: "Person",
		//	phone: "+61 234 567 890",
		//	general_info: "Example description/general notes",
		//	appt_reminder_sent: true,
		//	review_reminder_sent: true,
		//	contactable: true,
		//	message_history: fallbackMessages,
		//}
		//);

		if(authInfo.authed) {
			getContactInfo();

			// Poll contact info to enable message history
			const loopHandle = setInterval(() => {
				getContactInfo();
			}, 1000 * 10);

			// Return cleanup function
			return () => {
				clearInterval(loopHandle);
			}
		}
	}, [authInfo.fetchingAuth])
	


	return (
		<div className="p-8 w-full">
			<header>
				<Button
                    startIcon={<ArrowBackIosIcon />}
					onClick={() => navigate("/bookings")}
                >Back
                </Button>

			</header>

			<div className="pt-4 md:pt-0 flex-col-reverse md:flex-row flex justify-center">
				
				<MessageBox contactId={id || ""} messageHistory={
				   	contactInfo?.message_history || []
				} tempUpdateMessages={tempUpdateMessageHistory} />

				<ContactInformation contactInfo={contactInfo} />

			</div>
				
		</div>
	);
}


export default ContactProfile;

