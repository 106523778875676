// React Imports
import * as React from 'react';
import { useState, useEffect } from 'react';

import NumberedButton from "../ui/numberedButton";
import ServiceDisplay from "./service-display";
import MobileMenu from "./mobile-menu";

// Icons
import MarkChatUnreadOutlinedIcon from '@mui/icons-material/MarkChatUnreadOutlined';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import AdsClickOutlinedIcon from '@mui/icons-material/AdsClickOutlined';
import LoupeOutlinedIcon from '@mui/icons-material/LoupeOutlined';

// MUI Components
import TextField from "@mui/material/TextField";

export default function Landing() {


	// image imports
	const logo = require("../images/logo.png");
	const headerLogo = require("../images/header-logo.png");
	const clientRetention = require("../images/client-retention.png");

	const overview = require("../images/overall.png");
	const bookings = require("../images/addNewBooking.png");
	const ai = require("../images/ai-analysis.png");
	const reviews = require("../images/reminder 1.png");
	const marketing = require("../images/generalAds.png");
	const ads = require("../images/generalAds 1.png");

	const manHappy = require("../images/manhappy 1.png");


	// Service Description Component Array
	const serviceArray = [
	["Comprehensive Data Overview", "Enhance campaign management with our meticulously designed dashboard, providing instant access to all data in a visually appealing format for optimized control and efficiency.", overview],
	["No more no-shows", "Log bookings and appointments on our simple web-based interface, we'll automatically remind customers when their appointment is coming up.", bookings],
	["Your Analytics - Simplified", "We pass your online performance analytics through an AI-based interpretation algorithm to bring a mix of raw data and curated insights to you.", ai],
	["Boost Your Reviews", "We'll send out automatic personalized reminders to your customers with a 'one-click' link to leave a Google Review - launching your business up the search recommendation ladder.", reviews],
	["Flexible Marketing to Clients", "Send easily customisable messages to your entire clientele with the freedom to schedule them to repeat whenever you want.", marketing],
	["AI-Assisted Ad Creation", "Utilise Google's AI-drive Dynamic Search Ad system to quickly and easily supplement your advertising efforts right the the Startbase dashboard.", ads]
	];


	const [selectedService, setSelectedService] = useState(0);

	function displayServiceDescription(num: number): void {

		if(num < serviceArray.length) {
			setSelectedService(num);
		}
	}

	function scrollToContact() {
		const el = document.getElementById("contact-form");
		if(!el) return;
		window.scroll({
			top: el.getBoundingClientRect().top,
			behavior: "smooth"
		});
	}

    return (
        <div className="pt-12 font-lato bg-white w-screen overflow-x-hidden">
			{/* Header */}
			<div className="w-full flex flex-row justify-between pl-6 pr-6 md:pr-36 md:pl-36 pt-0 pb-0">
				<div className="font-lato flex flex-row align-center items-center">
					<img src={headerLogo}/>
				</div>
	
				<div className="hidden lg:block">
					<button className="text-[#565656] hover:underline mr-8" onClick={scrollToContact}>Contact Us</button>
					<button className="bg-[#43CBFF] text-white rounded-md p-12 pt-2 pb-2 hover:bg-[#21A9DD] transition-all" onClick={() => {window.location.href = '/login'}}>Log In {'>'}</button>
				</div>

                {/* Menu button 
                <div className="block lg:hidden">
                    <button className="rounded-full h-12 w-12 p-2 bg-[#cccccc]" onClick={() => setMenuOpen(true)}>
                        <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill="#000000" fill-rule="evenodd" d="M19 4a1 1 0 01-1 1H2a1 1 0 010-2h16a1 1 0 011 1zm0 6a1 1 0 01-1 1H2a1 1 0 110-2h16a1 1 0 011 1zm-1 7a1 1 0 100-2H2a1 1 0 100 2h16z"></path> </g></svg>
                    </button>
                </div>
                <div className={(menuOpen ? "absolute" : "hidden") + " top-0 right-0"}>
                    <ul>
                        <li><button>Log In</button></li>
                        <li><button>Contact Us</button></li>
                    </ul>
                </div>
                */}
                <MobileMenu scrollToContact={scrollToContact}/>

			</div>


			{/* Hero Section */}
			<div className="flex flex-col lg:flex-row items-center md:items-start pl-6 pr-6 md:pl-36 md:pr-36 pb-24 pt-0 lg:pt-20 bg-transparent">
				<div className="md:w-2/3 md:pr-24 pt-12">
					<h1 className=" text-4xl md:text-6xl font-extrabold font-lato pb-2 md:w-2/3">Startbase Management</h1>
					<p className="text-xl md:w-2/3 mt-4">
						Enhance the efficiency and productivity of your business operations through the integration of streamlined automation solutions.
					</p>
					<button
					className="bg-[#C82AF8] text-white rounded-md w-full md:w-fit p-6 pt-2 pb-2 hover:bg-[#A608D6] transition-all mt-6 text-xl font-semibold"
					onClick={scrollToContact}>
						Request a Demo
					</button>
				</div>

				<img src={logo} className="object-cover opacity-60 lg:opacity-100 mt-6 lg:mt-0 lg:right-48 lg:absolute"/>
			</div>
			
			{/* Features */}
			<div className="bg-[#F5FEFE] mt-[-330px] md:mt-0  w-screen h-fit pt-8 pb-8 relative z-10">
				<h1 className="text-center text-4xl md:text-6xl text-[#464748]">Features</h1>

				<div className="grid grid-cols-1 lg:grid-cols-3 lg:grid-rows-2 pl-6 pr-6 lg:pr-24 lg:pl-24 mt-24 gap-16 text-center">
					<div className="text-[#464748]">
						<MarkChatUnreadOutlinedIcon />
						<h2 className="font-semibold text-xl lg:text-md">Automated Reminders</h2>
						<h3>Automatically send reminders to your clients.</h3>
					</div>


					<div className="text-[#464748]">
						<PsychologyOutlinedIcon />
						<h2 className="font-semibold text-xl lg:text-md">AI Analytics Reports</h2>
						<h3>Improve your online prescence and growth.</h3>
					</div>


					<div className="text-[#464748]">
						<LanguageOutlinedIcon />
						<h2 className="font-semibold text-xl lg:text-md">Simple Booking Record</h2>
						<h3>Never lose track of bookings again.</h3>
					</div>

					<div className="text-[#464748]">
						<RateReviewOutlinedIcon />
						<h2 className="font-semibold text-xl lg:text-md">Centralized Marketing Portal</h2>
						<h3>Handle marketing messages with ease.</h3>
					</div>


					<div className="text-[#464748]">
						<AdsClickOutlinedIcon />
						<h2 className="font-semibold text-xl lg:text-md">Review Funnelling</h2>
						<h3>Track your increase in Google Reviews.</h3>
					</div>


					<div className="text-[#464748]">
						<LoupeOutlinedIcon />
						<h2 className="font-semibold text-xl lg:text-md">AI Assissted Ad Campaigns</h2>
						<h3>Efficiently craft Google Ads with AI support.</h3>
					</div>
				</div>
			</div>
			

			{/* Client Retention */}
			<div className="p-6 lg:p-32 pt-12 lg:flex lg:flex-row">
				<img src={clientRetention} className="hidden lg:block"/>
				
				<div className="lg:pl-16">
					<h1 className="text-4xl lg:text-6xl font-extrabold">Boost your client retention</h1>
					<p className="mt-8 text-xl lg:w-2/3">Using  proprietary algorithms and data management systems, Startbase's  tailored marketing suite will help you to keep you closely connected to  clientele - as well as analyzing and reporting on your digital marketing  and advertising efforts.</p>
				</div>

				<img src={clientRetention} className="block lg:hidden pt-8"/>
			</div>

			{/* Service Heading */}
			<div className="w-screen bg-[#D1D3D4]">
				<div className="bg-[url('./images/lamps.png')] md:h-[236px] bg-cover md:bg-contain bg-no-repeat bg-center">
					<h1 className="text-4xl text-[#464748] text-center pt-32 pb-12 md:pb-0">About our Services</h1>
				</div>
			</div>


			{/* Service Overview */}
			<div className="bg-[#F3F3F9] pt-12 pb-12">
				<div className="flex flex-row justify-evenly lg:pl-32 lg:pr-32 mb-8">
					
					<NumberedButton num={0} onChange={displayServiceDescription} selected={selectedService === 0}/>

					<NumberedButton num={1} onChange={displayServiceDescription} selected={selectedService === 1}/>

					<NumberedButton num={2} onChange={displayServiceDescription} selected={selectedService === 2}/>


					<NumberedButton num={3} onChange={displayServiceDescription} selected={selectedService === 3}/>

					<NumberedButton num={4} onChange={displayServiceDescription} selected={selectedService === 4}/>

					<NumberedButton num={5} onChange={displayServiceDescription} selected={selectedService === 5}/>

				</div>

				<div>
					<ServiceDisplay title={serviceArray[selectedService][0]} paragraph={serviceArray[selectedService][1]} image={serviceArray[selectedService][2]}/>
				</div>

			</div>


			{/* Pricing section */}
			<div className="w-screen bg-[#D1D3D4] p-8">
                <h1 className="text-center text-xl font-semibold">Pricing</h1>

                <p className="text-center text-4xl font-extrabold mt-4">Save hours in booking management and promotional marketing, <br/> focus on running your business!</p>

                <div className="flex flex-row justify-center mt-8">
                    {/* Basic Tier */}
                    <div className="rounded-xl bg-white p-8 ml-4 mr-4 shadow-2xl">
                        <h1 className="text-center font-semibold text-heading text-2xl mb-4">Basic</h1>

                        <h2 className="text-3xl font-extrabold text-center">$230 / Month</h2>

                        <ul className="list-['✓'] mt-4 text-lg">
                            <li className="pl-4">Booking Log</li>
                            <li className="pl-4">Automated Booking Reminders</li>
                            <li className="pl-4">Automatic Review Reminders</li>
                            <li className="pl-4">Reporting Dashboard</li>
                        </ul>
                        <ul className="list-['×'] text-lg text-gray-400">
                            <li className="pl-4">AI ad campaign analysis</li>
                            <li className="pl-4">Marketing message portal</li>
                            {/*<li className="pl-4"></li>*/}
                        </ul>
                        <button 
                            className="bg-[#C82AF8] w-full text-center text-white p-2 rounded-xl text-lg mt-12 font-semibold hover:bg-[#A608D6] transition-all"
                            onClick={() => {window.location.href = "/signup?init-tier=0"}}
                        >
                            Join Startbase</button>
                    </div>

                    {/* Pro tier */}
                    <div className="rounded-xl bg-white p-8 ml-4 mr-4 shadow-2xl border-[#C82AF8] border-[3px] border-solid">
                        <h1 className="text-center font-semibold text-heading text-2xl mb-4">Premium</h1>

                        <h2 className="text-3xl font-extrabold text-center"><span className="text-2xl  text-gray-400 line-through">$590 / Month</span> $490 / Month</h2>

                        <ul className="list-['✓'] mt-4 text-lg">
                            <li className="pl-4">Booking Log</li>
                            <li className="pl-4">Automated Booking Reminders</li>
                            <li className="pl-4">Automatic Review Reminders</li>
                            <li className="pl-4">Reporting Dashboard</li>
                            <li className="pl-4">AI ad campaign analysis</li>
                            <li className="pl-4">Marketing message portal</li>
                        </ul>
                        <button
                            className="bg-[#C82AF8] w-full text-center text-white p-2 rounded-xl text-lg mt-12 font-semibold hover:bg-[#A608D6] transition-all"
                            onClick={() => {window.location.href = "/signup?init-tier=1"}}
                        >
                            Join Startbase</button>
                    </div>
                </div>
			</div>


			{/* Contact Form */}
			<div className="lg:flex lg:flex-row bg-[#F5FEFE] p-6 lg:p-24 lg:pl-48 lg:pr-48 justify-evenly">
				<div>
					<h1 className="font-extrabold text-4xl lg:text-6xl">Get in touch with us</h1>
					<h2 className="text-lg pt-6 md:pt-0">To reach us, email directly or use the online form.</h2>

					<img src={manHappy} className="hidden lg:block"/>
				</div>

				<form>
					<div className="flex flex-col md:flex-row justify-center lg:justify-between" id="contact-form">
						<TextField label="First Name" placeholder="Type First Name" margin="normal" fullWidth className="bg-white"/>
						<div className="w-6"></div>
						<TextField label="Last Name"  placeholder="Type Last Name" margin="normal" fullWidth className="bg-white"/>
					</div>
					<TextField label="Email" placeholder="Type Email" fullWidth margin="normal" className="bg-white"/>

					<TextField multiline label="Message" placeholder="Type Message here" fullWidth margin="normal" rows={4} className="bg-white"/>
					
					<button className="bg-[#C82AF8] w-full text-center text-white p-4 rounded-xl text-2xl mt-4 font-semibold hover:bg-[#A608D6] transition-all">Send</button>
				</form>
			</div>

			
			{/* Footer */}
			<footer className="bg-black text-white text-sm p-12">
				<div className="flex flex-row justify-center">
					<a className="mr-2">Contact Us</a>
					|  
					<span className="ml-2">All rights reserved ©2024 Startbase Management</span>
				</div>
			</footer>


        </div>
    );
}
