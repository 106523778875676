import { useState, useEffect, useContext } from "react";
import { Button, TextField, Tabs, Tab } from "@mui/material";
import Sidebar from "../ui/sidebar";
import Paper from "@mui/material/Paper";
import Card from "@mui/material";
import Divider from "@mui/material/Divider";
import TopNav from "../ui/top-nav";
import { Badge, Event, AccessTime } from "@mui/icons-material";
import { AuthContext } from "../auth";


function previewMessage(input: string) {
	

	let outputString = '';
	
	const keywords = ['-name-', '-day-', '-time-'];

    type ReplacementDict = {
        '-name-': string,
        '-day-': string,
        '-time-': string
    }

	const replacements: ReplacementDict = {
        '-name-': '[Recipient\'s Name]', 
		'-day-': 'Tuesday', 
		'-time-': '12:30 PM'
    };
	let keywordFound = false;

	// Iterate through each word in the message
	input.split(' ').forEach((word: string) => {
		keywordFound = false;

		// Check all keywords
		Object.entries(replacements).forEach((keyPair) => {

			if(word.includes(keyPair[0])) {
				keywordFound = true;

				// If there's no punctuation replace with the corresponding replacement
				if(word === keyPair[0]) {
					outputString = outputString.concat(" ", keyPair[1]);
				} else {

					// Get base word and punctuation
					let baseWord = String(/-[A-Z0-9]*-/i.exec(word)) as keyof ReplacementDict;
					let init_punctuation = /.*-(.*)/.exec(word);
                    let punctuation = init_punctuation ? init_punctuation[1] : '';

					outputString = outputString.concat(" ", replacements[baseWord], punctuation);

				}
			}

		});
		if (!keywordFound) {
			outputString = outputString.concat(" ", word);
		}

	});
	//console.log(outputString);
	// Assign value
    return outputString;
}


export default function Reminders() {

	const authInfo = useContext(AuthContext);
    const [formIdx, setFormIdx] = useState(0);
    const [apptReminderText, setApptReminderText] = useState('');
    const [reviewReminderText, setReviewReminderText] = useState('');

	if(!authInfo) {
		throw new Error("Invalid auth context");
	}
	const userData = authInfo.userData;

	useEffect(() => {
		if(!authInfo.authed && !authInfo.fetchingAuth) {
			window.location.href = "/login";
		}
	}, [authInfo.fetchingAuth]);

	let accountData = userData?.account_data;

    let reminderMessages = [accountData ? accountData.appointment_message : '',
        accountData ? accountData.review_message : ''];

    //console.log('Messages:');
    //console.log(reminderMessages);



    if(reminderMessages[0] && reminderMessages[1] && !apptReminderText && !reviewReminderText) {
        setApptReminderText(reminderMessages[0]);
        setReviewReminderText(reminderMessages[1]);
    }

    async function submitReminderChanges() {
        const response = await fetch('/update-reminders', 
            {
                method: "POST",
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    'booking_reminder': apptReminderText,
                    'review_reminder': reviewReminderText
                })
            }
        );

        if(response.status !== 200) {
            alert("There was an error updating your reminder templates. Try again later.");
        }

        // Now fetch user data again to display the updated templates.
        window.location.reload();
    }

    return (

        <div className="bg-slate-100 h-screen">
            <Sidebar />

            <TopNav />

            <main className="md:ml-64 p-8 pt-28">

				<h1 className="text-2xl text-heading pb-8">Reminder Templates Editor</h1>

                <div className="bg-white rounded-md p-4">

                    <Tabs value={formIdx} onChange={(event: React.SyntheticEvent, newVal: number) => setFormIdx(newVal)}>
                        <Tab label="Appointment Reminder" className="text-xl" />
                        <Tab label="Review Reminder" className="text-xl" />
                    </Tabs>

                    <div className={"lg:p-8 flex flex-col md:flex-row justify-evenly" + (formIdx !== 0 ? " hidden" : "")}>
                        {/* Appointment Reminder editor */}
                        <div className="lg:w-1/2 w-full p-8">

                            <div className="text-center lg:flex lg:flex-row justify-evenly lg:justify-between mb-4">
                                <h1 className="text-lg text-heading">Edit</h1>
                                <div>
                                    <Button 
                                        onClick={() => setApptReminderText(apptReminderText + ' -name- ')}
                                    >
                                        <Badge className="mr-1"/> <span className="text-xs">Add Name</span>
                                    </Button>
                                    <Button 
                                        onClick={() => setApptReminderText(apptReminderText + ' -day- ')}
                                    >
                                        <Event className="mr-1 ml-2"/> <span className="text-xs">Add Date</span>
                                    </Button>
                                    <Button 
                                        onClick={() => setApptReminderText(apptReminderText + ' -time- ')}
                                    >
                                        <AccessTime className="mr-1 ml-2"/> <span className="text-xs">Add Time</span>
                                    </Button>
                                </div>
                            </div>

                            <TextField multiline onChange={(event) => setApptReminderText(event.target.value)} className="w-full" value={apptReminderText} rows={12}></TextField>
                            <Button variant="outlined" className="float-right top-4" onClick={() => {setApptReminderText('')}}>Clear</Button>
                        </div>

                        {/* Appointment Reminder Preview */}
                        <div className="bg-[#F4F9FF] p-8 rounded-md lg:w-1/2">
                            <h1 className="text-lg text-heading mb-4">Preview Template</h1>

                            <TextField multiline value={previewMessage(apptReminderText)} className="w-full" rows={12} variant="filled" inputProps={{readOnly: true,}}></TextField>
                        </div>
                    </div>


                    <div className={"lg:p-8 flex flex-col md:flex-row justify-evenly" + (formIdx !== 1 ? " hidden" : "")}>

                        {/* Review Reminder Editor */}
                        <div className="lg:w-1/2 w-full p-8">
                            <div className="text-center lg:flex lg:flex-row justify-evenly lg:justify-between mb-4">
                                <h1 className="text-lg text-heading">Edit</h1>
                                <div>
                                    <Button 
                                        onClick={() => setReviewReminderText(reviewReminderText + ' -name- ')}
                                    >
                                        <Badge className="mr-1"/> <span className="text-xs">Add Name</span>
                                    </Button>
                                    <Button 
                                        onClick={() => setReviewReminderText(reviewReminderText + ' -day- ')}
                                    >
                                        <Event className="mr-1 ml-2"/> <span className="text-xs">Add Date</span>
                                    </Button>
                                    <Button 
                                        onClick={() => setReviewReminderText(reviewReminderText + ' -time- ')}
                                    >
                                        <AccessTime className="mr-1 ml-2"/> <span className="text-xs">Add Time</span>
                                    </Button>
                                </div>
                            </div>
                            <TextField multiline onChange={(event) => setReviewReminderText(event.target.value)} className="w-full" value={reviewReminderText} rows={12}></TextField>
                            <Button variant="outlined" className="float-right top-4" onClick={() => {setReviewReminderText('')}}>Clear</Button>
                        </div>

                        {/* Appointment Reminder Preview */}
                        <div className="bg-[#F4F9FF] p-8 rounded-md lg:w-1/2">
                            <h1 className="text-lg text-heading mb-4">Preview Template</h1>
                            <TextField multiline value={previewMessage(reviewReminderText)} className="w-full" rows={12} variant="filled" inputProps={{readOnly: true,}}></TextField>
                        </div>
                    </div>

                    <div className="w-full text-center mt-12">
                        <Button variant="contained" className="w-fit" onClick={submitReminderChanges}>Save</Button>
                    </div>

                </div>

            </main>
        </div>

    );
}
