import { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import Sidebar from "../ui/sidebar";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import TextField from '@mui/material/TextField';
import { DatePicker, DateTimePicker, DateTimePickerProps, TimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Button, CircularProgress, Backdrop, Modal, FormControl, FormHelperText, Alert } from '@mui/material';
import BookingList from './booking-log';
import TopNav from '../ui/top-nav';
import { AuthContext } from "../auth";
import { Loupe, UploadFile } from '@mui/icons-material';


import { MDate, newMDate, addSecondsToDate } from "../types";


// Contact profile imports
import ContactProfile from "./profile";
import dayjs, { Dayjs } from 'dayjs';


export default function Bookings() {

	const authInfo = useContext(AuthContext);
	const form = useRef(null);

    const [time, setTime] = useState([0,0]);
    const [date, setDate] = useState(new Date());
    const [dateTime, setDateTime] = useState<dayjs.Dayjs | null>(null);
    const [phone, setPhone] = useState('');
    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [notes, setNotes] = useState("");

    const [viewBookings, setViewBookings] = useState(false);
    const [addingBooking, setAddingBooking] = useState(false);
    const [uploadingFile, setUploadingFile] = useState(false);
	const [windowSize, setWindowSize] = useState(window.innerWidth);

    const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
    const [submissionError, setSubmissionError] = useState<string | null>(null);
	
	if(!authInfo) throw new Error("Invalid auth context.");
	const userData = authInfo.userData;


	useEffect(() => {
		if(!authInfo.authed && !authInfo.fetchingAuth) {
			window.location.href = "/login";
		}
	}, [authInfo.fetchingAuth]);

	function handleResize() {
		setWindowSize(window.innerWidth);
	}
	useEffect(() => {
		window.addEventListener("resize", handleResize);

		return () => {window.removeEventListener("resize", handleResize)};
	});

    return (
        <div className="bg-slate-50 h-screen">
            <Sidebar />

            <Backdrop open={loading}>
                <CircularProgress />
            </Backdrop>

            <TopNav/>

            <main className="md:ml-64 p-8 pt-28">

                {/*viewBookings ? <BookingList showFn={setViewBookings} bookings={userData['booking_list'] || []}/> : <></>*/}


                <h1 className="text-heading text-2xl pb-8">Contact Management</h1>

                <div className="bg-white w-full m-auto pt-0 flex flex-col justify-center content-center rounded-md">
                    <div className="p-8">
                        <h1 className="text-[#2196F3] text-xl mb-4">Booking Details</h1>

						{/* For mobile layout */}
                        <div className="block md:hidden mb-4">
                            <TextField label="Search" placeholder="Name, email, etc..." sx={{width: "100%"}}/>
                        </div>

						
                        <div className="flex flex-col md:flex-row">
                            <div className="hidden md:block mr-4">
                                <TextField label="Search" placeholder="Name, email, etc..."/>
                            </div>

                            <button
							type="button"
							className="border-2 w-full md:w-fit rounded-md pl-4 pr-4 border-primary text-primary transition-all text-md hover:bg-[#0000000F] ml-auto pt-4 pb-4 md:pt-0 md:pb-0 md:mr-4 mb-4 md:mb-0"
							onClick={() => setUploadingFile(true)}>
								<UploadFile/> <span className="block sm:inline">UPLOAD BOOKINGS FROM FILE</span>
							</button>
							

                            <button
							type="button"
							onClick={() => setAddingBooking(true)}
                            className="bg-primary w-full md:w-fit pl-4 pr-4 rounded-md text-white transition-all text-md hover:bg-blue-font pt-4 pb-4 md:pt-0 md:pb-0">
							 	<Loupe/> <span className="block sm:inline">ADD NEW BOOKING</span>
                            </button>
                        </div>

                    </div>
                    <BookingList showFn={setViewBookings} bookings={userData?.booking_list || []}/>
                </div>

                {/* New booking form */}
                <Modal open={addingBooking} onClose={() => setAddingBooking(false)}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div className="p-4 md:p-10 pb-6 rounded-md bg-white w-5/6 md:w-2/3 absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
                            <h1 className="text-2xl font-bold text-center md:text-left text-heading mb-12">Add New Booking</h1>

                            <form action="/booking" method="post" encType="application/x-www-form-urlencoded" className="w-full" onSubmit={async (e: React.FormEvent<HTMLFormElement>) => {
								// Prevent initial form submission
								e.preventDefault();
                                setFormErrors({});
                                setSubmissionError(null);

                                const errors: { [key: string]: string } = {};

                                if (!firstName) {
                                    errors.firstName = "First name is required";
                                }
                                if (!surname) {
                                    errors.surname = "Last name is required";
                                }
                                if (phone.length < 5) {
                                    errors.phone = "Phone number is required";
                                }
                                if (!date) {
                                    errors.date = "Date and time are required";
                                }

                                if (Object.keys(errors).length > 0) {
                                    setFormErrors(errors);
                                    return; // Stop submission
                                }

								setAddingBooking(false);
								setLoading(true);

                                let data = {
                                    booking_type: "log",
                                    notes: notes,
                                    first_name: firstName,
                                    surname: surname,
                                    date: dateTime?.toISOString(),
                                    phone: phone,
                                    email: email
                                };

                                console.log(data);

                                try {
                                    let res = await fetch('/booking', {
                                        method: "POST",
                                        headers: {
                                            "Content-Type": "application/json"
                                        },
                                        body: JSON.stringify(data)
                                    });

                                    if (!res.ok) {
                                        const errorData = await res.json();
                                        throw new Error(errorData.message || "Failed to create booking");
                                    }

                                    authInfo.fetchUserData();
                                } catch (error: any) {
                                    console.error("Error creating booking:", error);
                                    setSubmissionError(error.message || "An error occurred during booking.");
                                } finally {
                                    setLoading(false);
                                }
							}}>
                                <input type='hidden' value="log" name="type"></input>
                                {submissionError && <Alert severity="error">{submissionError}</Alert>}
                                <div className='lg:flex justify-between'>
                                  <div className='flex flex-col justify-center mr-4 lg:w-1/2'>
                                    <h1 className='text-lg text-heading'>Personal Details</h1>

                                    <div className='flex flex-row justify-between'>
                                        <TextField
                                            label="First Name"
                                            variant='outlined'
                                            margin="normal"
                                            className="w-[48%]"
                                            onChange={(e: any) => setFirstName(e.target.value)}
                                            error={!!formErrors.firstName} // Show error state
                                            helperText={formErrors.firstName} // Display error message
                                        />
                                        <TextField
                                            label="Last Name"
                                            variant='outlined'
                                            margin="normal"
                                            className="w-[48%]"
                                            onChange={(e: any) => setSurname(e.target.value)}
                                            error={!!formErrors.surname}
                                            helperText={formErrors.surname}
                                        />
                                    </div>

                                    <h2 className="mt-2 text-heading text-md mb-2">Contact Methods</h2>
                                    <FormControl error={!!formErrors.phone}> {/* Wrap PhoneInput in FormControl */}
                                        <PhoneInput
                                            defaultCountry="au"
                                            value={phone}
                                            onChange={(value) => { setPhone(value); }}
                                            className="mb-2 z-10 w-full"
                                        />
                                        {formErrors.phone && <FormHelperText>{formErrors.phone}</FormHelperText>} {/* Display error */}
                                    </FormControl>
                                    <TextField type="email" label="Email" margin="normal" name="email" placeholder="Email" InputLabelProps={{ shrink: true }} onChange={(e) => setEmail(e.target.value)}/>
                                  </div>

                                  <div className='lg:ml-4 bg-slate-100 p-8 rounded-xl lg:w-1/2'>
                                    <h1 className='text-heading text-lg mb-2'>Booking Time and Date</h1>
                                    <FormControl error={!!formErrors.dateTime} className="w-full">
                                        <DateTimePicker
                                            label="Date and Time"
                                            value={dateTime}
                                            onChange={(newValue) => {
                                                setDateTime(newValue);
                                            }}
                                            slotProps={{ // Use slotProps instead of components
                                                textField: { // Target the TextField slot
                                                    fullWidth: true,
                                                    className: "bg-white",
                                                },
                                            }}
                                        />
                                        {formErrors.dateTime && <FormHelperText>{formErrors.dateTime}</FormHelperText>}
                                    </FormControl>
                                    <h1 className='text-heading text-lg mb-2'>Additional Notes</h1>
                                    <TextField multiline label="Additional Notes" placeholder="Type additional notes here" minRows={2} name='notes' className='bg-white w-full' onChange={(e) => setNotes(e.target.value)}/>
                                  </div>
                                </div>

                                <div className='flex flex-row justify-end gap-4 mt-6'>
                                  <Button type='button' variant='outlined' onClick={() => setAddingBooking(false)}>Cancel</Button>
                                  <Button type='submit' variant='contained' disabled={loading}>Confirm Booking</Button>
                                </div>

                            </form>

                        </div>
                    </LocalizationProvider>
                </Modal>

                {/* File upload modal */}
                <Modal open={uploadingFile} onClose={() => {setUploadingFile(false)}} >
                    <div className="bg-white w-1/2 absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 p-4 rounded-md">
                        <form 
                            action="/import" 
                            method="post" 
                            encType="multipart/form-data"
                            className="flex flex-col justify-center">

                            <h1 className='font-semibold text-heading'>Upload CSV Booking</h1>
                            <p className="text-main mt-4">Startbase will attempt to automatically extract information from booking logs, improving the software transition experience.</p>
                            <p className="text-main mt-4">As this process is completely automatic, some information may be missed - but we'll notify you if anything can't be processed correctly.</p>

                            <input name="file" type="file" className="w-1/2 self-center mb-4 mt-16"></input>
                            <Button variant="outlined" className="w-1/2 self-center" type="submit">Submit</Button>
                        </form>
                    </div>
                </Modal>

            </main>

        </div>
    );
}
