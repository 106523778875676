
import {Card} from "@mui/material";

export default function BudgetModal() {
    
    return(
		<Card className="w-full h-screen lg:h-fit lg:w-2/3 absolute lg:left-[16.7%] p-8 lg:mt-32">
            {/* Current budget list */}
            <div>
                <h1>Current Budgets</h1>
            </div>

            {/* New budget creation form */}
            <div>
                <h1>Create New Budget</h1>
            </div>
        </Card>
    );
}

