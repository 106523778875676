import React, { CSSProperties, useContext, useMemo, useState } from 'react';
import { Calendar, dayjsLocalizer, Views } from "react-big-calendar";
import dayjs from 'dayjs';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Booking, MDate } from '../types';
import { Box, Button, Typography, Modal, TextField, Select, MenuItem, InputLabel, FormControl, IconButton, List, ListItem, ListItemText, Divider, InputAdornment } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import isBetween from "dayjs/plugin/isBetween";
import { AuthContext } from "../auth";
import Sidebar from '../ui/sidebar';
import TopNav from '../ui/top-nav';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { SearchIcon } from 'lucide-react';

dayjs.extend(isBetween);
const localizer = dayjsLocalizer(dayjs);

interface CalendarEvent {
    title: string;
    start: Date;
    end: Date;
}

const MyToolbar = ({ date, view, onNavigate }: any) => {
    let displayLabel = '';

    if (view === Views.MONTH) {
        displayLabel = dayjs(date).format('MMMM YYYY');
    } else if (view === Views.WEEK) {
        const startOfWeek = dayjs(date).startOf('week');
        const endOfWeek = dayjs(date).endOf('week');
        displayLabel = `${startOfWeek.format('MMMM D')} - ${endOfWeek.format('MMMM D, YYYY')}`;
    } else if (view === Views.DAY) {
        displayLabel = dayjs(date).format('MMMM D, YYYY');
    } else if (view === Views.AGENDA) {
        displayLabel = `Agenda for ${dayjs(date).format('MMMM YYYY')}`;
    }

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2, px: 2 }}>
            <IconButton onClick={() => onNavigate('PREV')}>
                <ArrowBackIosNewIcon />
            </IconButton>
            <Typography variant="h6">{displayLabel}</Typography>
            <IconButton onClick={() => onNavigate('NEXT')}>
                <ArrowForwardIosIcon />
            </IconButton>
        </Box>
    );
};

const BookingCalendar = () => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const [selectedBookings, setSelectedBookings] = useState<Booking[]>([]);

    // Current day's bookings modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const [view, setView] = useState<'month' | 'week'>('month');
    const [filterText, setFilterText] = useState('');
    const authInfo = useContext(AuthContext);
    if (!authInfo) throw new Error("Invalid auth context.");

    const handleDayClick = (date: Date) => {

        const clickedDayStart = dayjs(date).startOf('day');
        const clickedDayEnd = dayjs(date).endOf('day');

        const bookingsOnDay = authInfo.userData?.booking_list.filter((booking) => {
            const bookingDate = dayjs(Number(booking.appt_date?.$date.$numberLong));
            return bookingDate.isBetween(clickedDayStart, clickedDayEnd, null, '[]');
        });

        setSelectedBookings(bookingsOnDay || []);
        setAnchorEl(document.createElement('div'));
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false); // Close the modal
    };

    const filteredBookingsForModal = useMemo(() => {
        return selectedBookings.filter((booking) => {
            const fullName = `${booking.first_name} ${booking.last_name}`.toLowerCase();
            return fullName.includes(searchQuery.toLowerCase());
        });
    }, [selectedBookings, searchQuery]);


    const handleViewChange = (newView: 'month' | 'week') => {
        setView(newView);
    };

    const filteredBookings = useMemo(() => {
      const allBookings = authInfo.userData?.booking_list || [];
      if (!filterText) {
        return allBookings; // Return all bookings if no filter applied
      }
      return allBookings.filter(booking => {
        const fullName = `${booking.first_name} ${booking.last_name}`.toLowerCase();
        return fullName.includes(filterText.toLowerCase());
      });
    }, [authInfo.userData?.booking_list, filterText]);

    const events = useMemo<CalendarEvent[]>(() => {
        return filteredBookings.map((booking) => {
            const apptDate = booking.appt_date?.$date.$numberLong;

            if (!apptDate) {
                console.warn("Booking has no appt_date:", booking);
                return null;
            }

            const timestamp = Number(apptDate);
            if (isNaN(timestamp) || timestamp <= 0) {
                console.warn("Invalid appt_date number:", apptDate, "for booking:", booking);
                return null;
            }

            try {
                const start = new Date(timestamp);
                if (isNaN(start.getTime())) {
                    console.warn("Invalid Date object created from timestamp:", timestamp, "for booking:", booking);
                    return null;
                }
                return {
                    title: `${booking.first_name} ${booking.last_name}`,
                    start,
                    end: new Date(start),
                };
            } catch (error) {
                console.error("Error creating date for booking:", booking, error);
                return null;
            }
        }).filter((event): event is CalendarEvent => event !== null);
    }, [filteredBookings]);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div className="bg-slate-50 h-screen">
            <Sidebar />
            <TopNav />

            <Box className="flex flex-col items-center md:ml-64 p-8 pt-28">
                <Box className="w-full mb-4 flex justify-between items-center">
                    <Typography variant="h5" component="h2" className="text-heading">
                        Bookings Calendar
                    </Typography>

                    <Box className="flex items-center space-x-4">
                        <TextField
                            label="Filter Bookings"
                            variant="outlined"
                            size="small"
                            value={filterText}
                            onChange={(e) => setFilterText(e.target.value)}
                        />
                        <FormControl>
                            <InputLabel id="view-select-label">View</InputLabel>
                            <Select
                                labelId="view-select-label"
                                id="view-select"
                                value={view}
                                label="View"
                                onChange={(e) => handleViewChange(e.target.value as 'month' | 'week')}
                                size="small"
                            >
                                <MenuItem value={'month'}>Month</MenuItem>
                                <MenuItem value={'week'}>Week</MenuItem>
                            </Select>
                        </FormControl>
                        <Button
                            variant="contained"
                            onClick={() => navigate('/bookings')}
                            style={{ backgroundColor: '#2196F3', padding: "0.75rem" }}
                        >
                            <PostAddIcon className="mr-2" /> Record New Booking
                        </Button>
                    </Box>
                </Box>

                <Box className="w-full bg-white rounded-md py-8">
                    <Box className="w-full max-w-4xl bg-white rounded-lg p-4 m-auto" sx={{ height: '60vh', flexGrow: 1 }}> {/* Added flexGrow for better responsiveness */}
                        <Calendar
                            localizer={localizer}
                            events={events}
                            startAccessor="start"
                            endAccessor="end"
                            style={{ height: '100%' }} // Use 100% height for better responsiveness
                            onSelectSlot={(slotInfo) => handleDayClick(slotInfo.start)}
                            views={["month", "week"]}
                            view={view}
                            onView={setView as any}
                            selectable
                            components={{
                                toolbar: MyToolbar
                            }}
                        />
                        <Modal // Use Modal instead of Popover
                            open={isModalOpen}
                            onClose={handleModalClose}
                            aria-labelledby="bookings-modal-title"
                            aria-describedby="bookings-modal-description"
                        >
                            <Box sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 400, // Increased width
                                bgcolor: 'background.paper',
                                border: '2px solid #000',
                                boxShadow: 24,
                                p: 4,
                                borderRadius: 2
                            }}>
                                <Typography id="bookings-modal-title" variant="h6" component="h2" mb={2}>
                                    Bookings for {selectedBookings.length > 0 ? dayjs(Number(selectedBookings[0].appt_date?.$date.$numberLong)).format('MMMM DD, YYYY') : 'Selected Day'}
                                </Typography>

                                <TextField
                                    label="Search Bookings"
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Divider sx={{my: 2}}/>
                                <List dense>
                                    {filteredBookingsForModal.length > 0 ? (
                                        filteredBookingsForModal.map((booking) => (
                                            <ListItem key={booking._id.$oid}>
                                                <ListItemText
                                                    primary={`${booking.first_name} ${booking.last_name}`}
                                                    secondary={dayjs(Number(booking.appt_date?.$date.$numberLong)).format('h:mm A')}
                                                />
                                            </ListItem>
                                        ))
                                    ) : (
                                        <Typography variant="body2" color="text.secondary">
                                            No bookings found.
                                        </Typography>
                                    )}
                                </List>
                                <Box mt={2} display="flex" justifyContent="flex-end">
                                    <Button onClick={handleModalClose}>Close</Button>
                                </Box>
                            </Box>
                        </Modal>
                    </Box>
                </Box>
            </Box>
        </div>
    );
}

export default BookingCalendar;
