import { Button, Backdrop, CircularProgress, Alert } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../auth";

// Icon imports
import { AddLink, LinkOff, Logout, Search } from "@mui/icons-material";

export default function TopNav() {

	const authInfo = useContext(AuthContext);

	// Loading state
	const [loading, setLoading] = useState(false);
	const [fetchingAds, setFetchingAds] = useState(false);

	const [logoutWarning, setLogoutWarning] = useState("hidden");


    // Check for valid token on page load
	if(!authInfo) throw new Error("Invalid auth context.");

	const auth = authInfo.googleAuthed;

	async function handleLogout() {
		try {
			const res = await fetch('/logout');

			if(res.status === 200) {
				// Logout successful
				window.location.href = '/login';
			} else {
				// Display alert
				setLogoutWarning("block");
			}
		} catch {
			setLogoutWarning("block");
		}
	}


	// Driver function for Google authentication
	function authDriver() {
		if(authInfo) {
			setLoading(true);
			setFetchingAds(true);

			authInfo.authGoogle();
		}
	}

	function googleDeauthDriver() {
		if(authInfo) {
			authInfo.unauthGoogle();
		}
	}


    return (
		<div>
            <div className="bg-nav-bg absolute top-0 shadow h-16 w-screen grid grid-cols-2 items-center z-20">

                <Backdrop open={authInfo?.fetchingAuth || loading}>
					<div className="text-white">
    					<CircularProgress color="inherit"/>
					</div>
					<div className="pl-8 z-50">
						<h1 className="text-4xl font-bold text-white">{fetchingAds ? "Linking Google Account" : "Setting up your dashboard."}</h1>
						{
						fetchingAds ?
							<h1 className="text-3xl text-white">This may take up to 1 minute.</h1>
						:
							<></>
						}
					</div>
    			</Backdrop>

    			<div className={logoutWarning}>
    				<Alert severity="error" onClose={() => {setLogoutWarning('hidden');}}>Logout failed - try again later.</Alert>
    			</div>


                {/*
                <div className="h-10 p-2 ml-20 w-64 md:ml-80 lg:w-80 rounded-md bg-white border-0 focus:border-2 border-blue-font hidden lg:inline">
                    <Search />
                    <input type="text" className="focus:outline-none pl-1" placeholder="Search" />
                </div>
                */}

                <div className="flex flex-row justify-end ml-80 lg:ml-0 absolute right-4">
                    <button className={`bg-white rounded-md text-blue-font h-10 border-2 border-blue-font 
                    pl-4 pr-4 self-center hover:bg-slate-200 transition-all mr-4 `}
                    onClick={() => { auth ? googleDeauthDriver() : authDriver()}}>

    					{auth ? <LinkOff /> : <AddLink />} 
						<span className="hidden md:inline">{(auth ? ' Disconnect' : ' Connect' ) + ' Google Account'}</span>

                    </button>

                    <button onClick={() => {if(authInfo) handleLogout();}} className="lg:after:content-['Logout'] bg-white lg:bg-transparent rounded-md pl-4 pr-4 border-black border-2 lg:border-0 lg:hover:bg-[#FFFFFF44] transition-all"><Logout className="lg:mr-2"/></button>
                </div>
            </div>
		</div>
    );
}
